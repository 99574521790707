
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan, SubscriptionStatus } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"

import { User } from "models/User"
import {UseOrganizationStore, Subscription as OrganizationSubscription, Update as UpdateOrganization} from 'stores/OrganizationStore';
import { Bootstrapper } from "Bootstrapper"
import {OrganizationContext} from './OrganizationContext'
import { DialogUtil } from "utils/DialogUtil"
import InviteUsersButton from "./InviteUsersButton"
import Link from "components/buttons/Link"
import AccessManagerButton from "./AccessManagerButton"
import { MembershipInfo } from "models/MembershipInfo"
import ManageDevicesButton from "views/authentication/ManageDevicesButton"


const UserMenu = (props: any) => {
    const {isAdmin, disableRevoke, user, membership, onOpenDeviceManager, onRevokeAccessAsync, onReactivateMemberAsync, onReplaceOrgOwnerAsync, ...rest} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const m: MembershipInfo = membership;
    const u: User = user;

    
    
    const menuClicked = (event: any) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation()
    };

    const deviceMgrMenuItemClicked = async(e: any) => {
        handleClose(e);
        onOpenDeviceManager();
    }

    const revokeMenuItemClicked = async(e: any, user: User) => {
        handleClose(e);
        await onRevokeAccessAsync(user);
    }

    const reactivateMemberMenuItemClicked = async(e: any, user: User) => {
        handleClose(e);
        await onReactivateMemberAsync(user);
    }

    const replaceOrgOwnerMenuItemClicked = async(e: any, user: User) => {
        handleClose(e);
        await onReplaceOrgOwnerAsync(user);
    }


    
    const handleClose = (e: any) => {
        setAnchorEl(null);
        e.stopPropagation();
    };

    return(
        <>
        <IconButton onClick={(e: any) => menuClicked(e)}>
            <Icon normal>more_vert</Icon>
        </IconButton>
        
            <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={(e: any) => handleClose(e)}
            >
                {/* <MenuItem onClick={(e: any) => deviceMgrMenuItemClicked(e)}>
                    Manage Devices
                </MenuItem> */}
                <MenuItem disabled={disableRevoke} onClick={(e: any) => revokeMenuItemClicked(e, user)}>
                    Remove Member
                </MenuItem>
                {m.deactivatedMembership && <MenuItem onClick={(e: any) => reactivateMemberMenuItemClicked(e, user)}>
                    Activate Member
                </MenuItem>}
                {isAdmin && <MenuItem onClick={(e: any) => replaceOrgOwnerMenuItemClicked(e, user)}>
                    Replace Owner
                </MenuItem>}

            </Menu>
        </>
    )

}


const Users = (props: any) => {
    const navigate = useNavigate();
    const context = Bootstrapper.getInstance<OrganizationContext>(OrganizationContext);
    const dialogUtils = new DialogUtil();

    const userService = new UserService();
    const tenantService = new TenantService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const organization = UseOrganizationStore();
    const isAdmin = userService.isAdmin();

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [plan, setPlan] = useState<Plan>();
    const [ownerId, setOwnerId] = useState<string>(userService.getActiveUserId());
    const [users, setUsers] = useState<User[]>([]);
    const [membershipInfoMap, setMembershipInfoMap] = useState<Map<string, MembershipInfo>>(new Map());

    const [inviteCount, setInviteCount] = useState<number>(0);
    const [contextUser, setContextUser] = React.useState<User>(null);
    const activeUserId = userService.getActiveUserId();

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
        loadDataAsync();
    }, []);

    const getMembershipMapAsync = async() => {
        var map = new Map<string, MembershipInfo>();
        const memberInfo = await tenantService.getOrganizationMembershipInfoAsync(organization.id);
        memberInfo.forEach(m => {
            map.set(m.userId, m);
        })
        return map;
    }

    const loadDataAsync= async() => {
        context.setBusyIndicator(true);
        const users = await userService.getUsersByOrgIdAsync(organization.id);
        const memberMap = await getMembershipMapAsync();
        var plan = await tenantService.getOrganizationPlanAsyn(organization.id);
        var ownerId = await tenantService.getOrganizationOwnerIdAsyn(organization.id);
        const invites = await tenantService.getInvitationsAsync(organization.id);
        setInviteCount(invites.length)
        setUsers(users);
        setMembershipInfoMap(memberMap);
        setPlan(plan);
        setOwnerId(ownerId);
        context.setBusyIndicator(false);
    }

    const refreshMembershipsAsync = async() => {
        context.setBusyIndicator(true);
        var map = await getMembershipMapAsync();
        setMembershipInfoMap(map);
        context.setBusyIndicator(false);

    }
    
    const onRevokeAccessAsync = async(user: User) => {
        dialogUtils.confirmDialog(
            `Are you sure you want remove member: ${user.userName}?`,
            async(confirm) => {
                if(!confirm)
                    return;
                
                var result = await tenantService.revokeAccessToOrganizationAsync(organization.id, user.id);
                if(result)
                {
                    context.setBusyIndicator(true);
                    if(userService.getActiveUserId() == user.id)
                        navigate("/account");
                    else{
                        const users = await userService.getUsersByOrgIdAsync(organization.id);
                        setUsers(users);
                    }
                    context.setBusyIndicator(false);
                }
            }
        )        
    }


    const onReactivateMemberAsync = async(user: User) => {
        dialogUtils.confirmDialog(
            `Are you sure you want to give access to member: ${user.userName}?`,
            async(confirm) => {
                if(!confirm)
                    return;
                
                var result = await tenantService.reactivateTenantMemberAsync(organization.id, user.id);
                if(result)
                {
                    await refreshMembershipsAsync()
                }
            }
        )        
    }


    const onReplaceOrgOwnerAsync = async(user: User) => {
        dialogUtils.confirmDialog(
            `Are you sure you want to set this user as the Organization owner: ${user.userName}?`,
            async(confirm) => {
                if(!confirm)
                    return;
                
                var result = await tenantService.replaceOrganizationOwnerAsync(organization.id, user.id);
                if(result)
                {
                    await refreshMembershipsAsync()
                }
            }
        )        
    }


    const getUsersList = (users: User[], membershipMap: Map<string, MembershipInfo>) => {
        return(
            <>
                <Table className={isMobile ? "responsive-table" : ""} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell align="left">Has Access</TableCell>

                            <TableCell align="left">Role</TableCell>

                            {/* <TableCell align="center">Confirmed Email</TableCell> */}
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => {
                            const m = membershipMap.get(user.id);
                            const color = m?.deactivatedMembership ? 'red' : null;
                            return (
                                <TableRow
                                key={user.id}
                            >
                                <TableCell data-label="Email" component="th" scope="row">{user.userName}</TableCell>
                                
                                <TableCell data-label="Has Access" align={"left"} scope="row">
                                    <Icon color={color}>{m?.deactivatedMembership ? 'close' : 'check'}</Icon>
                                </TableCell>

                                <TableCell data-label="Role" align={"left"} scope="row">
                                    {MembershipInfo.getRoleLabel(m)}
                                </TableCell>

                                {/* <TableCell data-label="Confirmed Email" align="center" scope="row">{user.emailConfirmed.toString()}</TableCell> */}
                                <TableCell data-label="Action" align={isMobile ? 'left' : "right"} scope="row">
                                    <UserMenu
                                        isAdmin={isAdmin}
                                        disableRevoke={user.id == ownerId || user.id == activeUserId}
                                        user={user}
                                        membership={m}
                                        onRevokeAccessAsync={onRevokeAccessAsync}
                                        onReactivateMemberAsync={onReactivateMemberAsync}
                                        onReplaceOrgOwnerAsync={onReplaceOrgOwnerAsync}
                                        onOpenDeviceManager={() => setContextUser(user)} />
                                </TableCell>
                            </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </>
        )
    }
    const maxUsers = plan?.maxUsers ?? 0;
    const activeUsers = users.filter(u => !membershipInfoMap.get(u.id).deactivatedMembership);
    const invitesAvailable = (plan == null || plan.status != SubscriptionStatus.Active) ? 0 : Math.max(0, plan.maxUsers - activeUsers.length - inviteCount);
    const requiresAccessManager = activeUsers.length > maxUsers;
    const hasDeactivatedMembers = Array.from(membershipInfoMap.values()).some(m => m.deactivatedMembership);
    const displayAccessManagerBtn = requiresAccessManager || (invitesAvailable > 0 && hasDeactivatedMembers);
    return(
        <Section disablePadding id="section-plans" bgColor={bg1}>
                <Animation duration="medium" overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        <Stack spacing={1}>
                            <div>
                                {plan && <Text m={0} pb={1}>{Plan.getFullNameLabel(plan)} allows up to {maxUsers} {'user' + (maxUsers == 1 ? '' : 's')}</Text>}
                                <Stack alignItems='center' direction='row' spacing={1}>
                                    <InviteUsersButton disabled={invitesAvailable == 0} onSubmit={async() => await loadDataAsync()} maxInvites={invitesAvailable} />{inviteCount > 0 && <Link to={`/organization/${organization?.id}/invitations`}>Pending invites ({inviteCount})</Link>}
                                    {(isAdmin || displayAccessManagerBtn) && <AccessManagerButton onSuccess={refreshMembershipsAsync} orgId={organization?.id} orgUsers={users} orgPlan={plan} />}
                                </Stack>
                            </div>

                            <Paper>
                                    {getUsersList(users, membershipInfoMap)}
                            </Paper>                    
                        </Stack>
                        {/* {contextUser && <ManageDevicesButton 
                            tenantId={organization.id} 
                            open={contextUser != null} 
                            onClose={() => setContextUser(null)} 
                            hideButton 
                            userId={contextUser.id}/>} */}
                    </div>
                </Animation>
        </Section>

    )


}


export default Users