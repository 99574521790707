
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import Link from "components/buttons/Link"
import LoadingButton from "components/buttons/LoadingButton"
import { Tenant } from "models/Tenant"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import { TenantService } from "services/TenantService"
import OrganizationInfoForm from "views/organization/OrganizationInfoForm"
import useInterval from "hooks/UseInterval"
import { BadRequestError, NotFoundError } from "errors/ApplicationErrors"




const IntegrationResult = (props: any) => {
    const success = props.success;
    const navigate = useNavigate();
    const userService = new UserService();
    const isAuthenticated = userService.isAuthenticated();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [errorMsg, setErrorMsg] = useState<string>();  
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const msg = searchParams.get('err');
        setErrorMsg(msg);
    }, []);


    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    const handleSuccessBtn = () => {
        if(isAuthenticated)
            navigate('/account/integrations');
        else
            navigate('/');

    }

    return(
        <Section sx={{flexGrow: 1}} bgColor={bg2}>
            <Container maxWidth="xs">
                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        {success && <Form title="Integration Successful!" action={<Button fullWidth onClick={() => handleSuccessBtn()}>{isAuthenticated ? "Account" : "Home"}</Button>} />}  
                        {!success && <Form 
                            title="Integration Failed" 
                            action={<Button fullWidth onClick={() => handleSuccessBtn()}>{isAuthenticated ? "Account" : "Home"}</Button>}
                            validationMsg={errorMsg}
                            />}
                    </div>
                </Animation>
            </Container>
        </Section>
    )

}

export default IntegrationResult